import React from 'react'
import Layout from '../components/layout'
import Post from '../components/Post'
import { graphql } from 'gatsby'
import authors from '../util/authors'
import { Row, Col, Container } from 'reactstrap'
import Jumbotron from '../components/Jumbotron'
import SEO from "../components/seo"

const authorPosts = ({ data, pageContext }) => {
  const { totalCount } = data.allMarkdownRemark
  const author = authors.find(x => x.name === pageContext.authorName)
  const pageHeader = `${totalCount} Posts by: ${pageContext.authorName}`

  return (
    <Layout
      pageTitle={pageHeader}
      postAuthor={author}
      authorImageFluid={data.file.childImageSharp.fluid}
    >
      <SEO 
        title={`${author.name} Artikel`} 
          lang="id"
          img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fcartoon-man-reading-book-sitting-stack-big-books-hot-coffee-mug-blue_18591-63800.jpg?alt=media&token=a5b61c66-dec9-4d3c-9b99-63fb10031cc9"
          description="Kumpalan Tim di Waingapu Open Mind"      
      />    

      <Jumbotron title="Penulis Di Waingapu Open Mind" info="Para penulis di Waingapu Open Mind yang berbaik hati dalam membagikan informasi yang bermanfaat." />
      <Container>
        <h1 className="text-center">{totalCount} Postingan Oleh {author.name}</h1>
        <Row>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Col md="4">
              <Post
                key={node.id}
                slug={node.fields.slug}
                title={node.frontmatter.title}
                author={node.frontmatter.author}
                date={node.frontmatter.date}
                body={node.excerpt}
                tags={node.frontmatter.tags}
                fluid={node.frontmatter.image.childImageSharp.fluid}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  )
}

export const authorQuery = graphql`
  query($authorName: String!, $imageUrl: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___postNumber], order: ASC }
      filter: { frontmatter: { author: { eq: $authorName } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            author
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    file(relativePath: { eq: $imageUrl }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default authorPosts
